﻿import {
  AdditionalOfferData,
  CardContents, GameGraphics,
  MediumLarge,
} from '../../Components/Tiles/tileTypes';
import { assertNever } from '../../assertions';
import { NotImplementedError } from '../../Errors';

export default (data: CardContents, additionalData?: AdditionalOfferData): MediumLarge => {
  switch (data.contentType) {
  case 'MyOffer': {

    let gameGraphics: GameGraphics | undefined;
    if (additionalData?.game?.graphics?.background?.url) {
      gameGraphics = {
        background: additionalData.game.graphics.background.url,
        foreground: additionalData.game?.graphics?.foreground?.url,
      };
    } else {
      gameGraphics = {
        background: data.teaserImage.src ?? ''
      };
    }

    return {
      tileSize: 'MediumLarge',
      gameGraphics
    } satisfies MediumLarge;
  }
  case 'Casino': throw new NotImplementedError(data.contentType);
  default: {
    assertNever(data);
  }
  }
};