﻿import {
  AdditionalOfferData,
  CardContents, CasinoLiveData,
  LiveCasinoLiveData,
  SizeTypes,
  TileProps,
} from './tileTypes';
import { NotImplementedError } from '../../Errors';
import { assertNever } from '../../assertions';
import AdaptOffers from '../../adapters/ContentType/AdaptOffer';
import AdaptMediumLarge from '../../adapters/Size/AdaptMediumLarge';
import { Offer } from '../../apiTypes/PromoApi/PromoOffer';
import { Game as CasinoGame } from '../../apiTypes/GameService';

type Size = SizeTypes['tileSize'];
type Content = CardContents['contentType'];

type BaseProps = {
  tileSize: Size;
  contentType: Content;
}

export function tileAdapter(size: Size, content: Content, data: Array<Offer | CasinoGame>, additionalData?: Array<LiveCasinoLiveData | CasinoLiveData | AdditionalOfferData>): TileProps[] {
  const key = `${content}-${size}` as const;

  const base: BaseProps = {
    tileSize: size,
    contentType: content
  };

  switch (key) {
  case 'MyOffer-MediumLarge': {
    return (data as Array<Offer>).map((rawOffer) => {
      const associatedGame = (additionalData as Array<AdditionalOfferData>)?.find((ad) => ad.crmServiceId === rawOffer.offerMetadata.crmServiceId);

      const myOffer = AdaptOffers(rawOffer, associatedGame);
      const mediumLarge = AdaptMediumLarge(myOffer, associatedGame);

      return {
        ...base,
        ...myOffer,
        ...mediumLarge
      };
    });
  }
  case 'Casino-Large':
  case 'Casino-Medium':
  case 'Casino-Micro':
  case 'Casino-Numbered':
  case 'Casino-Small':
  case 'MyOffer-Large':
  case 'MyOffer-Medium':
  case 'MyOffer-Micro':
  case 'MyOffer-Numbered':
  case 'MyOffer-Small':
  case 'Casino-MediumLarge': {
    throw new NotImplementedError(key);
  }
  default: {
    assertNever(key);
  }
  }
}
