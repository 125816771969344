﻿import ApiRequest from '@SharedScripts/Helpers/ApiRequest';
import { PromoResponse } from '../apiTypes/PromoApi/PromoOffer';
import { FreeSpinResponse } from '../apiTypes/PromoApi/PromoFreeSpin';

const baseUrl = 'https://promo.danskespil.dk/da/promo/api/';

export const getPromoOffers = async () => {
  const url = baseUrl + 'offers';
  return await ApiRequest<PromoResponse>({ url, withCredentials: true });
};

export const getOfferDetails = async (offerId: string) => {
  if (!offerId) return Promise.reject('offerId cannot by empty');
  const url = `${baseUrl}freespin?offerId=${offerId}&isOfferActive=true`;
  return await ApiRequest<FreeSpinResponse>({ url, withCredentials: true });
};

export const getEntainLoginStatus = async () => {
  const res = await ApiRequest<boolean>({
    url: '/dli/scapi/entain/isAuthenticated'
  });

  return res;
};

export const clearEntainAuthCookies = async () => {
  return await ApiRequest<boolean>({
    url: '/dli/scapi/entain/logout'
  });
};