import React from 'react';
import MediumLargeMyOfferTile from './MediumLargeTile/MediumLargeOfferTile';
import { TileProps, MediumLargeOffer } from './tileTypes';
import { assertNever } from '../../assertions';
import { NotImplementedError } from '../../Errors';

export type DynamicTile2Props = {
  data: TileProps;
  className?: string;
}

export const DynamicTile2 = ({ data, className }: DynamicTile2Props) => {
  const { tileSize, contentType } = data;

  const getTileModule = () => {
    const key = `${contentType}-${tileSize}` as const;

    switch (key) {
    case 'MyOffer-MediumLarge': return <MediumLargeMyOfferTile className={className} {...(data as MediumLargeOffer)}/>;
    case 'Casino-Large':
    case 'Casino-Medium':
    case 'Casino-Micro':
    case 'Casino-Numbered':
    case 'Casino-Small':
    case 'MyOffer-Large':
    case 'MyOffer-Medium':
    case 'MyOffer-Micro':
    case 'MyOffer-Numbered':
    case 'MyOffer-Small':
    case 'Casino-MediumLarge': {
      throw new NotImplementedError(key);
    }
    default:
      assertNever(key);
    }
  };

  return (
    <div className={'relative'}>
      <>
        {getTileModule()}
      </>
    </div>
  );
};
