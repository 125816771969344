﻿import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { clearEntainAuthCookies, getEntainLoginStatus, getOfferDetails, getPromoOffers } from '../../api/PromoApi';
import { OfferCategoryType } from '../../apiTypes/PromoApi/PromoOffer';
import OfferCategory from './OfferCategory';
import { AdditionalOfferData, TileProps } from '../Tiles/tileTypes';
import { tileAdapter } from '../Tiles/tileAdapter';
import { getGames, getInternalIdFromExternalId } from '../../api/CasinoApi';
import { assertGuid } from '../../assertions';
import { getParameter } from '@SharedScripts/Utils/Url';

export default () => {
  const [categories, setCategories] = useState<Array<OfferCategoryType>>([]);
  const [offers, setOffers] = useState<Array<TileProps>>([]);
  const isEntainAuthSet = useRef<boolean>(false);
  const isApp = getParameter('dsApplicationId') === 'TRIFORK_CASINO_MOBILENATIVE';

  const promoOffers = useQuery({
    queryKey: ['promo'],
    queryFn: getPromoOffers
  });

  const gameOffers = promoOffers?.data?.offerDetailsList?.filter((offer) => {
    return offer.offerMetadata?.crmServiceId
      && offer.offerMetadata.serviceType === 'free_spin';
  }) ?? [];

  const entainLoginStatus = useQuery({
    queryKey: ['entainAuth'],
    queryFn: getEntainLoginStatus
  });

  const additionalOfferData: Array<AdditionalOfferData> = gameOffers.map((offer) => {
    return {
      crmServiceId: offer.offerMetadata.crmServiceId as string
    } satisfies AdditionalOfferData;
  });

  const freeSpin = useQueries({
    queries: gameOffers.map((offer) => {
      return {
        queryKey: ['freespin', offer.offerMetadata?.crmServiceId],
        queryFn: () => getOfferDetails(offer.offerMetadata?.crmServiceId as string),
        enabled: !promoOffers.isLoading
      };
    }) ?? [],
    combine: (result) => {
      result.forEach((r, i) => {
        if (r.data?.freeSpinMetaData?.eligibleGames[0]) {
          additionalOfferData[i].entainId = r.data.freeSpinMetaData.eligibleGames[0];
        }
      });

      return {
        isLoading: result.some((r) => r.isLoading),
        providerGameIds: result.map((r) => r.data?.freeSpinMetaData?.eligibleGames).filter(Boolean).flat()
      };

    }
  });

  const gameIds = useQueries({
    queries: freeSpin.providerGameIds.map((externalId) => {
      return {
        queryKey: ['externalToInternal', externalId],
        queryFn: () => getInternalIdFromExternalId(externalId as string),
        enabled: !freeSpin.isLoading
      };
    }),
    combine: (result) => {
      result.forEach((r, i) => {
        const offer = additionalOfferData[i];
        const id = r.data?.id;
        if (!id || !offer) return;
        assertGuid(id);
        offer.dsId = id;
      });

      return {
        isLoading: result.some((r) => r.isLoading),
        ids: result.map((r) => r.data?.id).filter(Boolean)
      };
    }
  });


  const games = useQuery({
    queryKey: ['getGames', gameIds.ids],
    queryFn: () => getGames(gameIds.ids as Array<string>),
    enabled: !gameIds.isLoading && Boolean(gameIds.ids.length),
  });

  useEffect(() => {
    if (games.isLoading) return;
    if (!games.data) return;

    for (const additionalData of additionalOfferData) {
      const game = games.data.find((g) => g.id === additionalData.dsId);
      if (game) {
        additionalData.game = game;
      }
    }
  }, [games.isLoading]);

  useEffect(() => {
    if (!promoOffers.data) return;

    let categories: Array<OfferCategoryType> = Object.values(promoOffers.data.offerCategories ?? []).filter((category) => category.categoryTitle !== 'Aktive kampagner');
    if (isApp) {
      categories = categories.filter((category) => category.categoryTitle?.toLowerCase() === 'casino');
    }

    const offers = promoOffers.data.offerDetailsList;

    const offerData = tileAdapter('MediumLarge', 'MyOffer', offers ?? [], additionalOfferData);

    setOffers(offerData);
    setCategories(categories);

  }, [promoOffers.isSuccess, games.isSuccess]);

  const entainLogout = useMutation({
    mutationFn: clearEntainAuthCookies
  });

  const clearEntain = () => {
    entainLogout.mutate();
  };

  useEffect(() => {
    if (entainLogout.isSuccess) {
      promoOffers.refetch();
    }
  }, [entainLogout]);

  useEffect(() => {
    const logoutBtn = document.querySelector('a.js-account-menu-logout-trigger');
    if (logoutBtn) {
      logoutBtn.addEventListener('click', clearEntain);
    }

    return () => {
      logoutBtn?.removeEventListener('click', clearEntain);
    };
  }, []);

  useEffect(() => {
    if (entainLoginStatus.data && !isEntainAuthSet.current) {
      isEntainAuthSet.current = true;
      promoOffers.refetch();
    }
  }, [entainLoginStatus]);

  useEffect(() => {
    let count = 0;
    const entainAuthInterval = setInterval(() => {
      if (isEntainAuthSet.current || count === 10) {
        clearInterval(entainAuthInterval);
        return;
      }

      entainLoginStatus.refetch();

      count++;
    }, 1000);

    return () => {
      clearInterval(entainAuthInterval);
    };
  }, []);

  const getOffers = (indices: number[]): TileProps[] => {
    return indices.map((index) => offers[index]).filter(Boolean);
  };

  return (
    <div
      className={`max-w-1152 bg-neutrals-coolGrey-100 ${!isApp ? 'rounded-t-32' : ''} px-[--grid-margin] pt-32 sm:pt-40 md:px-64 md:pt-64 md:w-full -mx-[--grid-margin] md:mx-auto `}
    >
      {categories.map((category) => {
        return <OfferCategory isApp={isApp} key={category.categoryTitle} {...category} offers={getOffers(category.offerDetailsIndexes ?? [])}/>;
      })}
    </div>
  );
};