﻿import { Cta, Offer } from '../../apiTypes/PromoApi/PromoOffer';
import { AdditionalOfferData, MyOffer } from '../../Components/Tiles/tileTypes';
import { toUrlFriendlyName } from '../../helpers/stringConverter';
import { getParameter } from '@SharedScripts/Utils/Url';

const bingoGames = ['bingostuen', 'bankohallen', 'guldhjulet', 'deal or no deal', 'bankofredag'];
const isApp = getParameter('dsApplicationId') === 'TRIFORK_CASINO_MOBILENATIVE';

export default (data: Offer, additionalData?: AdditionalOfferData): MyOffer => {
  const offer = data;
  let remainingDays: number | undefined = undefined;

  if (offer.offerMetadata.endDate) {
    const end = new Date(offer.offerMetadata.endDate);

    const diff = end.getTime() - Date.now();
    remainingDays = Math.ceil(diff / (1000 * 3600 * 24));
  }

  let link: Cta | undefined = offer.offerContent.moreInfoCta || (offer.offerMetadata.serviceSubType === 'PICK_A_BOX' || offer.offerMetadata.serviceSubType === 'SPIN_THE_WHEEL' ? offer.offerContent.ctaLinks?.more_info : undefined);

  if (!link) {
    let url = '';

    if (offer.offerMetadata.applicableProducts?.includes('bingo')) {
      let isBingoGameFound = false;
      for (const bingoGame of bingoGames) {
        const bingoGameNameInDescription = offer.offerContent.keyTermsAndConditions.toLowerCase().includes(bingoGame);
        if (bingoGameNameInDescription) {
          url = '/bingo/spil/' + bingoGame;

          isBingoGameFound = true;
          break;
        }
      }
      if (!isBingoGameFound) {
        url = '/bingo';
      }
    } else if (additionalData?.game?.title) {
      url = isApp ? `dscasino://ul/game_launch/${additionalData.game.id}` : `/casino/spil/${toUrlFriendlyName(additionalData.game.title)}?launch&fullscreen`;
    } else {
      const brand = offer.offerMetadata.applicableProducts?.[0] ?? 'casino';

      url = '/' + (brand === 'casino' && isApp ? 'dscasino://ul/lobby-casino' : brand);
    }

    link = {
      url,
      text: 'Spil nu',
      attributes: {
        class: 'primary' // used as button variant
      }
    };
  } else if (link.attributes?.class) {
    link.attributes.class = 'secondary'; // used as button variant
  }

  return {
    contentType: 'MyOffer',
    isLoading: false,
    endDate: offer.offerMetadata.endDate,
    crmServiceId: offer.offerMetadata.crmServiceId,
    startDate: offer.offerMetadata.startDate,
    gameTitle: additionalData?.game?.title,
    gameId: additionalData?.dsId,
    serviceSubType: offer.offerMetadata.serviceSubType,
    remainingDays,
    ctaLinks: offer.offerContent.ctaLinks,
    moreInfoCta: link,
    keyTermsAndConditions: offer.offerContent.keyTermsAndConditions,
    offerStateString: offer.offerContent.offerStateString,
    title: offer.offerContent.title,
    teaserImage: offer.offerContent.teaserImage,
    offerProgress: offer.offerMetadata.offerProgress,
    expiryTimeCountDown: offer.offerContent.expiryTimeCountDown,
    applicableProducts: offer.offerMetadata.applicableProducts
  };
};