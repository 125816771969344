﻿import { OfferCategoryType } from '../../apiTypes/PromoApi/PromoOffer';
import React from 'react';
import { DynamicTile2 } from '../Tiles/GenericTile';
import { TileProps } from '../Tiles/tileTypes';

const OfferCategory = (props: OfferCategoryType & { offers: TileProps[], isApp: boolean }) => {

  return (
    <div className={'pb-64'}>
      {!props.isApp && <div className={'text-24 font-bold mb-16'}>{props.categoryTitle}</div>}
      <div className={'grid sm:grid-cols-[repeat(2,_minmax(0,_1fr))] lg:grid-cols-[repeat(3,_minmax(0,_1fr))] gap-x-28 gap-y-64'}>
        {props.offers.map((offer, i) => {
          return <DynamicTile2 key={i} data={offer} className={'h-full'}/>;
        })}
      </div>
    </div>
  );
};

export default OfferCategory;